<template>
  <div>
    <van-cell-group v-for="(item, index) in list" :key="index">
      <template #title>
        <div>{{ item.productName }}</div>
        <div class="f-cb">
          <div>SKU{{ $t('colon') + item.productCode }}</div>
          <div>Total{{ $t('colon') + item.tradeNum }}</div>
        </div>
      </template>
      <van-cell
        v-for="(item, index) in item.children"
        :key="index"
        :title="$t('channelLabelCode') + $t('colon') + item.channelLabelCode"
        :value="$t('replenishNum') + $t('colon') + item.tradeNum"
      />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.listReplenishLogAll()
  },
  methods: {
    async listReplenishLogAll() {
      const { tradeNumber } = this.$route.query
      const { data } = await this.$api.listReplenishLogAll({ tradeNumber })
      console.log('listReplenishLogAll===>', data)
      const list = []
      for (const item of data) {
        const { productCode, productName, tradeNum, channelLabelCode } = item
        const index = list.findIndex((v) => v.productCode === productCode)
        if (index === -1) {
          list.push({
            productCode,
            productName,
            tradeNum,
            children: [{ channelLabelCode, tradeNum }],
          })
        } else {
          list[index].children.push({ channelLabelCode, tradeNum })
          list[index].tradeNum += tradeNum
        }
      }
      this.list = list
    },
  },
}
</script>